<template>
  <TTView>
    <VRow>
      <VCol>
        <CopyrightCard :copyright="copyright" />
      </VCol>
    </VRow>
  </TTView>
</template>

<script>
import CopyrightCard from '../../../components/contentStorage/copyright/CopyrightCard.vue';

export default {
  name: 'CopyrightView',

  components: {
    CopyrightCard,
  },

  data() {
    return {
      loading: false,
      copyright: {
        id: '',
        name: '',
        prerollUrl: '',
        postrollUrl: '',
        watermarkUrl: '',
      },
    };
  },

  computed: {
    copyrightId() {
      return this.$route.params.copyrightId;
    },
  },

  async created() {
    await this.fetch();
  },

  methods: {
    async fetch() {
      try {
        this.loading = true;

        const { copyrightId } = this;
        const data = { id: copyrightId };
        const { copyright } = await this.$di.api.ContentStorage.copyrightGet({ data });

        this.copyright = copyright;
      } catch (err) {
        this.$di.notify.errorHandler(err);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
